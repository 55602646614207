import React, { useEffect, useState, useCallback, useRef } from "react";
import "./transfer.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { db } from "../../firebase";
import {
  updateDoc,
  doc,
  getDoc,
  collection,
  onSnapshot,
  addDoc,
} from "firebase/firestore";
import { FaExclamationTriangle, FaCheckCircle } from "react-icons/fa";
import { useParams, useNavigate } from "react-router-dom";
import Loading from "../../components/Loading/Loading";
import { useLoggedUser } from "../../context/LoggedUserContext";

const Transfer = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [receiverId, setReceiverId] = useState("");
  const [receiver, setReceiver] = useState(null);
  const [sharesToTransfer, setSharesToTransfer] = useState("");
  const [transferReason, setTransferReason] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isTransferring, setIsTransferring] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const pricePerShare = 1;
  const { loggedUser } = useLoggedUser();

  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;
    return () => (isMounted.current = false);
  }, []);

  const debounce = (func, delay) => {
    let debounceTimer;
    return function (...args) {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func(...args), delay);
    };
  };

  const handleSearchChange = (value) => {
    if (isMounted.current) {
      setSearchTerm(value);
    }
  };

  const handleInputChange = (value) => {
    setInputValue(value);
    debouncedHandleSearch(value);
  };

  const debouncedHandleSearch = useCallback(
    debounce(handleSearchChange, 300),
    []
  );

  const fetchCurrentUser = useCallback(async () => {
    const docSnap = await getDoc(doc(db, "users", userId));
    if (docSnap.exists()) {
      if (isMounted.current) {
        setCurrentUser({ id: docSnap.id, ...docSnap.data() });
        setIsLoading(false);
      }
    }
  }, [userId]);

  useEffect(() => {
    fetchCurrentUser();
  }, [fetchCurrentUser]);

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "users"), (snapshot) => {
      const usersList = snapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .filter((user) => user.id !== userId);
      if (isMounted.current) {
        setUsers(usersList);
      }
    });

    return () => {
      unsubscribe();
      isMounted.current = false;
    };
  }, [userId]);

  useEffect(() => {
    const fetchReceiverData = async () => {
      const receiverDoc = await getDoc(doc(db, "users", receiverId));
      if (receiverDoc.exists) {
        setReceiver(receiverDoc.data());
      }
    };

    if (receiverId) fetchReceiverData();
  }, [receiverId]);

  const handleTransfer = async () => {
    setIsTransferring(true);

    if (currentUser.status === "pending" || receiver?.status === "pending") {
      setError("Transfer not allowed for users with pending status.");
      setIsTransferring(false);
      return;
    }

    if (!transferReason.trim()) {
      setError("Reason for transfer is required.");
      setIsTransferring(false);
      return;
    }

    if (!receiver) {
      setError("Please select a receiver before making a transfer.");
      setIsTransferring(false);
      return;
    }

    const numSharesToTransfer = Number(sharesToTransfer);

    if (
      !numSharesToTransfer ||
      isNaN(numSharesToTransfer) ||
      numSharesToTransfer <= 0
    ) {
      setError("Please enter a valid number of shares.");
      setIsTransferring(false);
      return;
    }

    if (numSharesToTransfer > Number(currentUser.share)) {
      setError("You can't transfer more shares than you own!");
      setIsTransferring(false);
      return;
    }

    try {
      const currentUserRef = doc(db, "users", currentUser.id);
      const receiverRef = doc(db, "users", receiverId);
      const receiverDoc = await getDoc(receiverRef);
      if (!receiverDoc.exists()) {
        setError("Receiver not found!");
        return;
      }

      const receiverData = receiverDoc.data();

      await updateDoc(currentUserRef, {
        share: Number(currentUser.share) - numSharesToTransfer,
        price:
          (Number(currentUser.share) - numSharesToTransfer) * pricePerShare,
      });
      await updateDoc(receiverRef, {
        share: Number(receiverData.share) + numSharesToTransfer,
        price:
          (Number(receiverData.share) + numSharesToTransfer) * pricePerShare,
      });
      const transferDocRef = await addDoc(collection(db, "transfers"), {
        senderId: currentUser.id,
        senderName: currentUser.displayName,
        receiverId: receiverId,
        receiverName: receiver?.displayName,
        sharesTransferred: numSharesToTransfer,
        reason: transferReason,
        date: new Date().toISOString(),
        inputter: loggedUser?.name || "Unknown Staff",
      });

      const transferId = transferDocRef.id;

      await addDoc(collection(currentUserRef, "transactions"), {
        type: "transfer",
        transferId: transferId, // Add this line
        timeStamp: new Date(),
        priceChange: -numSharesToTransfer * pricePerShare,
        priceAdded: 0,
        updatedPrice: currentUser.price - numSharesToTransfer * pricePerShare,
        details: `Transferred to ${receiver.displayName}`,
      });

      // For the receiver
      await addDoc(collection(receiverRef, "transactions"), {
        type: "transfer",
        transferId: transferId, // Add this line
        timeStamp: new Date(),
        priceChange: numSharesToTransfer * pricePerShare,
        priceAdded: 0,
        updatedPrice: receiver.price + numSharesToTransfer * pricePerShare,
        details: `Received from ${currentUser.displayName}`,
      });

      setSuccess("Shares transferred successfully!");
      setTimeout(() => {
        navigate("/users");
      }, 200);

      setCurrentUser((prev) => ({
        ...prev,
        share: prev.share - sharesToTransfer,
      }));
      setReceiver((prev) => ({
        ...prev,
        share: prev.share + sharesToTransfer,
      }));

      setIsTransferring(false);
    } catch (error) {
      setError("An error occurred during the transfer.");
      setIsTransferring(false);
    }
  };

  const filteredUsers = users.filter((user) => {
    return (
      (user.displayName &&
        user.displayName.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (user.card && user.card.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  });

  if (!currentUser || isLoading) {
    return <Loading />;
  }

  return (
    <div className="transfer">
      <Sidebar />
      <div className="transferContainer">
        <Navbar />
        <div className="transferContent">
          <h1>Transfer Shares</h1>
          <div className="section">
            <div className="leftSide">
              <h2>Sender's Details</h2>
              <div className="infoItem">
                <label>Name:</label>
                <span>{currentUser.displayName}</span>
              </div>
              <div className="infoItem">
                <label>Shares Available: </label>
                <span>{currentUser.share}</span>
              </div>
              <div className="infoItem">
                <label>Price: </label>
                <span>GH₵ {currentUser.price}</span>
              </div>
              <div className="infoItem">
                <label>Branch Code: </label>
                <span>{currentUser.code}</span>
              </div>
              <div className="infoItem">
                <label>Date of Birth: </label>
                <span>{currentUser.dob}</span>
              </div>
              <div className="infoItem">
                <label>Ghana Card: </label>
                <span>{currentUser.card}</span>
              </div>
            </div>
          </div>

          <div className="search">
            <input
              type="text"
              placeholder="Search by name or card..."
              value={searchTerm}
              onChange={(e) => handleInputChange(e.target.value)}
            />

            {searchTerm.length > 2 && (
              <div className="dropdown">
                {filteredUsers.map((user) => (
                  <div
                    key={user.id}
                    className="dropdownItem"
                    onClick={() => {
                      setReceiverId(user.id);
                      setSearchTerm(""); // clear the search term
                    }}
                  >
                    {user.displayName || "Unnamed User"} - {user.card}
                  </div>
                ))}
                {filteredUsers.length === 0 && (
                  <div className="noResults">No results found</div>
                )}
              </div>
            )}
          </div>

          <div className="section">
            <div className="rightSide">
              <h2>Receiver's Details</h2>
              {receiver && (
                <>
                  <div className="infoItem">
                    <label>Name:</label>
                    <span>{receiver.displayName}</span>
                  </div>
                  <div className="infoItem">
                    <label>Shares Available: </label>
                    <span>{receiver.share}</span>
                  </div>
                  <div className="infoItem">
                    <label>Price: </label>
                    <span>GH₵ {receiver.price}</span>
                  </div>
                  <div className="infoItem">
                    <label>Branch Code: </label>
                    <span>{receiver.code}</span>
                  </div>
                  <div className="infoItem">
                    <label>Date of Birth: </label>
                    <span>{receiver.dob}</span>
                  </div>
                  <div className="infoItem">
                    <label>Ghana Card: </label>
                    <span>{receiver.card}</span>
                  </div>
                </>
              )}
              <input
                type="number"
                placeholder="Shares to transfer"
                value={sharesToTransfer}
                onChange={(e) => setSharesToTransfer(e.target.value)}
              />
              <div className="reasonForTransfer">
                <h3>Reasons for Transfer</h3>
                <textarea
                  placeholder="Reason for transfer"
                  value={transferReason}
                  onChange={(e) => setTransferReason(e.target.value)}
                  required
                ></textarea>
              </div>
              <button
                onClick={handleTransfer}
                disabled={isTransferring || !receiver}
              >
                {isTransferring ? "Transferring..." : "Transfer"}
              </button>
            </div>
          </div>
          {error && (
            <p className="errorMsg">
              <FaExclamationTriangle /> {error}
            </p>
          )}

          {success && (
            <p className="successMsg">
              <FaCheckCircle /> {success}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Transfer;
