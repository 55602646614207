import { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "./home.scss";
// import Datatable from "../../components/datatable/Datatable";
import Chart from "../../components/chart/Chart";
import { auth, db } from "../../firebase"; // Ensure you have these exported from your firebase.js or similar file
import { doc, getDoc } from "firebase/firestore";
import Loading from "../../components/Loading/Loading";
import { Link } from "react-router-dom";

const Home = () => {
  const [currentStaff, setCurrentStaff] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (auth.currentUser) {
      const staffDocRef = doc(db, "staff", auth.currentUser.uid);
      getDoc(staffDocRef)
        .then((docSnap) => {
          if (docSnap.exists()) {
            setCurrentStaff(docSnap.data());
          }
        })
        .catch((error) => {
          console.error("Error fetching staff:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <Navbar />
        <div className="homeHeader">
          <h1>
            Welcome,{" "}
            {`${currentStaff?.name} - ${currentStaff?.role || "Staff"}`}
          </h1>
        </div>
        <div className="datatableTitle">
          {/* Shareholders */}
          <Link to="/users/new" className="link">
            Add New Shareholder
          </Link>
        </div>
        {/* <div className="charts">
          <Chart title="Users" aspect={2 / 1} />
        </div> */}
        {/* <div className="listContainer">
          <div className="listTitle">Share Holders Info</div>
          <Datatable />
        </div> */}
      </div>
    </div>
  );
};

export default Home;
