import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

const PrivateRoute = ({ children, readOnlyRoles = [], allowedRoles = [] }) => {
  const { currentUser } = useContext(AuthContext);

  // If user isn't authenticated
  if (!currentUser) {
    return (
      <div>
        You're not authorized to access this page. Contact the administrator for
        access credentials.
        <Navigate to="/" replace />
      </div>
    );
  }

  // If the user is a super-user, grant full access
  if (currentUser.role === "super-user") {
    return children;
  }

  // Explicitly deny access to admin for the /profile route
  if (currentUser.role === "admin" && readOnlyRoles.includes("profile")) {
    return (
      <div>
        You're not authorized to access this page. Contact the administrator for
        access credentials.
        <Navigate to="/" replace />
      </div>
    );
  }

  // If the user has a role that is in the readOnlyRoles array, grant read-only access
  if (readOnlyRoles.includes(currentUser.role)) {
    return React.cloneElement(children, { readOnly: true });
  }

  // Check if user has one of the allowed roles
  if (allowedRoles.length && allowedRoles.includes(currentUser.role)) {
    return children;
  }

  // If none of the above conditions are met, deny access
  return (
    <div>
      You're not authorized to access this page. Contact the administrator for
      access credentials.
      <Navigate to="/" replace />
    </div>
  );
};

export default PrivateRoute;
