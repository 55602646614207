import { useEffect } from 'react';

export default function useAutoLogout(logoutFunction, delay = 300000) { // 300000ms is 5 minutes
  useEffect(() => {
    let logoutTimer;

    const events = ['mousemove', 'keydown', 'scroll'];

    const resetTimer = () => {
      clearTimeout(logoutTimer);
      logoutTimer = setTimeout(() => {
        logoutFunction();
      }, delay);
    };

    for (let event of events) {
      window.addEventListener(event, resetTimer);
    }

    resetTimer();

    return () => {
      clearTimeout(logoutTimer);
      for (let event of events) {
        window.removeEventListener(event, resetTimer);
      }
    };
  }, [logoutFunction, delay]);
}




  
