import "./loading.scss";

const Loading = () => (
  <div className="loading">
    <div className="spinner"></div>
    <p>Fetching Data...</p>
  </div>
);

export default Loading;
