import "./userLog.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { auth, db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import { FaArrowLeft } from "react-icons/fa";

const UserLog = () => {
  const location = useLocation();
  const transaction = location.state?.transaction;
  const [inputter, setInputter] = useState(null);
  const [reason, setReason] = useState(transaction?.reason);
  const [data, setData] = useState([]);
  const isMounted = useRef(true);

  const navigate = useNavigate();

  useEffect(() => {
    isMounted.current = true;

    const fetchData = async () => {
      if (transaction?.inputter && !inputter) {
        const staffDocRef = doc(db, "staff", transaction.inputter);
        try {
          const docSnap = await getDoc(staffDocRef);
          if (docSnap.exists() && isMounted.current) {
            setInputter(docSnap.data().name);
          }
        } catch (error) {
          console.error("Error fetching staff:", error);
        }
      }

      if (
        transaction?.type === "transfer" &&
        reason === undefined &&
        transaction.transferId
      ) {
        try {
          const transferDocRef = doc(db, "transfers", transaction.transferId);
          const transferDocSnap = await getDoc(transferDocRef);

          if (transferDocSnap.exists() && isMounted.current) {
            const transferData = transferDocSnap.data();
            if (transferData?.reason) {
              setReason(transferData.reason);
            }
          }
        } catch (error) {
          console.error("Error fetching transfer:", error);
        }
      }
    };
    fetchData();

    return () => {
      isMounted.current = false;
    };
  }, [transaction, reason, inputter]);

  if (!transaction) return null;

  return (
    <div className="user-log">
      <button className="backArrow" onClick={() => navigate(-1)}>
        <FaArrowLeft />
      </button>
      <h3>Transaction Log</h3>
      <div className="log-details">
        <p>
          <strong>Date:</strong> {transaction.formattedDate}
        </p>
        <p>
          <strong>Time:</strong> {transaction.formattedTime}
        </p>
        <p>
          <strong>Details:</strong>
          {transaction.details ||
            (transaction.type === "transfer" ? "Transfer" : "Purchase")}
        </p>
        <p>
          <strong>Price Change:</strong> {transaction.priceChange}
        </p>
        <p>
          <strong>Amount Added:</strong> {transaction.priceAdded || "-"}
        </p>
        {transaction.type === "transfer" && (
          <p>
            <strong>Reason:</strong> {reason}
          </p>
        )}

        {inputter && (
          <p>
            <strong>Inputter:</strong> {inputter}
          </p>
        )}
      </div>
    </div>
  );
};

export default UserLog;
