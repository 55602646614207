import "./userHistory.scss";
import { useEffect, useState } from "react";
import { db } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { doc, collection, query, getDocs, orderBy } from "firebase/firestore";
import { FaUser } from "react-icons/fa";
import Loading from "../Loading/Loading";

const UserHistory = ({ userId }) => {
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const itemsPerPage = 5;

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const transactionsCollection = collection(
          doc(db, "users", userId),
          "transactions"
        );
        const transactionsQuery = query(
          transactionsCollection,
          orderBy("timeStamp", "desc")
        );
        const transactionsSnapshot = await getDocs(transactionsQuery);

        const transactionsData = transactionsSnapshot.docs.map((doc) => {
          const data = doc.data();
          const dateObj = data.timeStamp?.toDate();
          data.formattedDate = dateObj?.toLocaleDateString();
          data.formattedTime = dateObj?.toLocaleTimeString();
          return { ...data, id: doc.id };
        });

        setTransactions(transactionsData);
        setIsLoading(false);
      } catch (err) {
        setError(err.message);
        setIsLoading(false);
      }
    };

    fetchTransactions();
  }, [userId]);

  const displayedTransactions = transactions.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  if (isLoading) {
    return <Loading />;
  }
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="user-history">
      <h2>User Transaction History</h2>
      {transactions.length > 0 ? (
        <>
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Time</th>
                <th>Details</th>
                <th>Price Change (GH₵)</th>
                <th>Amount Added (GH₵)</th>
                <th>Transfer Amount (GH₵)</th>
                <th>Log</th>
              </tr>
            </thead>
            <tbody>
              {displayedTransactions.map((transaction, index) => (
                <tr key={index}>
                  <td>{transaction.formattedDate}</td>
                  <td>{transaction.formattedTime}</td>
                  <td>
                    {transaction.details ||
                      (transaction.type === "transfer"
                        ? "Transfer"
                        : "Purchase")}
                  </td>
                  <td>
                    {transaction.type === "transfer"
                      ? transaction.updatedPrice
                      : transaction.priceChange}
                  </td>
                  <td>{transaction.priceAdded || "-"}</td>
                  <td>
                    {transaction.type === "transfer"
                      ? transaction.priceChange
                      : "-"}
                  </td>
                  <td
                    onClick={() =>
                      navigate(`/user/log/${transaction.id}`, {
                        state: { transaction },
                      })
                    }
                  >
                    <FaUser className="log-icon" />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {selectedTransaction && (
            <div className="selected-transaction-details">
              <h3>Selected Transaction Details:</h3>
              {/* ... rest of the selected details */}
            </div>
          )}
          <div className="pagination">
            <button
              onClick={() =>
                setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
              }
              disabled={currentPage === 1}
            >
              ←
            </button>
            <span>{currentPage}</span>
            <button
              onClick={() =>
                setCurrentPage((prevPage) =>
                  Math.min(
                    prevPage + 1,
                    Math.ceil(transactions.length / itemsPerPage)
                  )
                )
              }
              disabled={
                currentPage === Math.ceil(transactions.length / itemsPerPage)
              }
            >
              →
            </button>
          </div>
        </>
      ) : (
        <p>No transactions found for this user.</p>
      )}
    </div>
  );
};

export default UserHistory;
