import React, { Component } from 'react';

class ErrorBoundary extends Component {
    state = { hasError: false, error: null };

    static getDerivedStateFromError(error) {
        return { hasError: true, error: error };
    }

    componentDidCatch(error, errorInfo) {
        console.error("Uncaught error:", error, errorInfo);
        // Here you could also log error messages to an error reporting service if you have one.
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="error-boundary">
                    <h1>Something went wrong.</h1>
                    {/* Optional: Display the error message to developers or for debugging purposes */}
                    {/* <details style={{ whiteSpace: 'pre-wrap' }}>
                        {this.state.error?.toString()}
                    </details> */}
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
