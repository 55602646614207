import './new.scss';
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import { useEffect, useState } from 'react';
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  setDoc,
  runTransaction,
} from 'firebase/firestore';
import { auth, db, storage } from '../../firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';
import useStaffDetails from '../../hooks/useStaffDetails';

const New = ({ inputs, title }) => {
  const [file, setFile] = useState('');
  const [data, setData] = useState({});
  const [per, setPerc] = useState(null);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const { staffDetails } = useStaffDetails();

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) {
      setErrors((prev) => ({ ...prev, file: 'Please select an image.' }));
      return;
    }

    // File Size Validation
    const maxSize = 5 * 1024 * 1024; // 5MB
    if (selectedFile.size > maxSize) {
      setErrors((prev) => ({
        ...prev,
        file: 'Image size should not exceed 5MB.',
      }));
      return;
    }

    // File Type Validation (you can add or remove formats as needed)
    const allowedFormats = [
      'image/jpeg',
      'image/jpg',
      'image/png',
      'image/gif',
    ];
    if (!allowedFormats.includes(selectedFile.type)) {
      setErrors((prev) => ({
        ...prev,
        file: 'Please upload a valid image format (JPEG, JPG, PNG, GIF).',
      }));
      return;
    }

    // If everything's fine, proceed with setting the file
    setFile(selectedFile);
  };

  const handleInputChange = (e, inputId) => {
    handleInput(e);
  };

  useEffect(() => {
    if (!file) return;

    const storageRef = ref(storage, new Date().getTime() + file.name);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        setPerc((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      },
      (error) => {
        console.log(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setData((prev) => ({ ...prev, img: downloadURL }));
        });
      }
    );
  }, [file]);

  const validateFields = () => {
    let tempErrors = {};

    if (!data.phone || data.phone.length !== 10) {
      tempErrors.phone = 'Please enter a valid 10-digit phone number.';
    }

    if (!data.code || data.code === '') {
      tempErrors.code = 'Please select a branch code.';
    }

    if (!file) {
      tempErrors.file = 'Please upload an image.';
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleInput = (e) => {
    const { id, value } = e.target;

    let newErrors = {};

    if (id === 'phone' && value.length > 10) {
      newErrors.phone = 'Phone number must be exactly 10 digits.';
    }

    setData((prevData) => ({ ...prevData, [id]: value }));
    setErrors((prev) => ({ ...prev, ...newErrors }));
  };

  const handleAdd = async (e) => {
    e.preventDefault();

    if (!validateFields()) return;

    try {
      // 1. Register the user with Firebase Authentication
      const authRes = await createUserWithEmailAndPassword(
        auth,
        data.email,
        data.password
      );

      const updateDatabase = async (transaction) => {
        const lastMembershipRef = doc(db, 'metadata', 'lastMembershipNum');
        const lastMembershipDoc = await transaction.get(lastMembershipRef);

        // Use 0 as default if it doesn't exist (first-time case)
        let lastMembershipNum = lastMembershipDoc.exists()
          ? lastMembershipDoc.data().value
          : 0;
        const newMembershipNum = lastMembershipNum + 1;

        // Generate new membership code
        const membershipCode = `KPRB${String(newMembershipNum).padStart(
          4,
          '0'
        )}`;

        const newUser = {
          ...data,
          uid: authRes.user.uid, // store the user's authentication UID for reference
          membershipCode: membershipCode,
          role: 'user',
          status: 'pending',
          timeStamp: serverTimestamp(),
          addedBy: staffDetails?.name || 'Unknown Staff',
        };

        transaction.set(doc(db, 'users', authRes.user.uid), newUser);

        // Update the membership number
        transaction.set(lastMembershipRef, { value: newMembershipNum });
      };

      // 2. Handle the Firestore transaction for membership and user profile
      await runTransaction(db, updateDatabase);
      navigate(-1);
    } catch (error) {
      console.error('Error adding user:', error);
      // Handle the specific error for email already in use
      if (error.code === 'auth/email-already-in-use') {
        setErrors((prev) => ({
          ...prev,
          email: 'This email address is already registered.',
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          general: 'An error occurred while registering. Please try again.',
        }));
      }
    }
  };

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          <div className="left">
            <img
              src={
                file
                  ? URL.createObjectURL(file)
                  : 'https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg'
              }
              alt=""
            />
          </div>
          <div className="right">
            <form onSubmit={handleAdd}>
              <div className="formInput">
                <label htmlFor="file">
                  Image: <DriveFolderUploadOutlinedIcon className="icon" />
                </label>
                <input
                  type="file"
                  id="file"
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                />
                {errors.file && <div className="error">{errors.file}</div>}
              </div>
              {inputs.map((input) => (
                <div className="formInput" key={input.id}>
                  <label>{input.label}</label>
                  {input.type === 'select' ? (
                    <select
                      id={input.id}
                      onChange={handleInput}
                      required={input.isRequired !== false}
                      value={data[input.id] || ''}
                    >
                      {input.options.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <input
                      id={input.id}
                      type={input.type}
                      placeholder={input.placeholder}
                      onChange={handleInput}
                      required={input.isRequired !== false}
                      value={data[input.id] || ''}
                    />
                  )}
                  {errors[input.id] && (
                    <div className="error">{errors[input.id]}</div>
                  )}
                </div>
              ))}
              {errors.general && <div className="error">{errors.general}</div>}

              <button disabled={per !== null && per < 100} type="submit">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default New;
