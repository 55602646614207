import './updateUser.scss';
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import { useEffect, useState } from 'react';
import { doc, serverTimestamp, updateDoc, getDoc } from 'firebase/firestore';
import { db, storage } from '../../firebase';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { useNavigate, useParams } from 'react-router-dom';
import { collection, addDoc } from 'firebase/firestore';
import Loading from '../../components/Loading/Loading';

const UpdateUser = ({ inputs, title }) => {
  const [file, setFile] = useState(null);
  const [data, setData] = useState({
    status: '',
    img: '',
    price: '',
    share: '',
  });
  const [per, setPerc] = useState(null);
  const [additionalData, setAdditionalData] = useState({
    price: '',
    shares: '',
  });
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { userId } = useParams();
  const [originalPrice, setOriginalPrice] = useState(0);
  const [originalShares, setOriginalShares] = useState(0);

  const handleInput = (e) => {
    const id = e.target.id;
    const value = e.target.value;

    if (id === 'additionalPrice' && data.status === 'approved') {
      if (value === '' || !isNaN(value)) {
        const addedValue = value ? Number(value) : 0;
        const newTotalPrice = originalPrice + addedValue;
        const newTotalShares = originalShares + addedValue;

        setAdditionalData({
          price: addedValue,
          shares: addedValue,
        });

        setData((prevData) => ({
          ...prevData,
          share: newTotalShares,
          price: newTotalPrice,
        }));
      }
    } else {
      setData({ ...data, [id]: value });
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      await updateDoc(doc(db, 'users', userId), {
        ...data,
        timeStamp: serverTimestamp(),
      });
      if (additionalData.price > 0) {
        const transactionsCollection = collection(
          doc(db, 'users', userId),
          'transactions'
        );
        await addDoc(transactionsCollection, {
          priceAdded: additionalData.price,
          priceChange: data.price,
          sharesAdded: additionalData.shares,
          timeStamp: serverTimestamp(),
        });
      }
      setAdditionalData({ price: '', shares: '' });
      navigate(-1);
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  useEffect(() => {
    let isMounted = true;
    const fetchUserData = async () => {
      if (userId) {
        try {
          const docSnap = await getDoc(doc(db, 'users', userId));
          if (docSnap.exists() && isMounted) {
            setData(docSnap.data());
            setOriginalPrice(docSnap.data().price);
            setOriginalShares(docSnap.data().share);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
      if (isMounted) setIsLoading(false);
    };
    fetchUserData();
    return () => (isMounted = false);
  }, [userId]);

  useEffect(() => {
    if (!file) return;
    let isMounted = true;
    const uploadUserFile = async () => {
      const name = new Date().getTime() + file.name;
      const storageRef = ref(storage, name);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          if (isMounted) setPerc(progress);
        },
        (error) => console.error('Error uploading file:', error),
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            if (isMounted) setData((prev) => ({ ...prev, img: downloadURL }));
          } catch (error) {
            console.error('Error fetching download URL:', error);
          }
        }
      );
    };
    uploadUserFile();
    return () => (isMounted = false);
  }, [file]);

  const shouldDisableInput = (inputId) => {
    const editableFieldsForApproved = [
      'email',
      'address',
      'additionalPrice',
      'file',
    ];
    const editableFieldsForPending = [
      'displayName',
      'other',
      'previous',
      'dob',
      'gender',
      'email',
      'address',
      'phone',
      'price',
      'share',
      'code',
      'channel',
      'account',
      'kin',
      'card',
      'file',
      'kin-contact',
    ];

    if (
      data.status === 'approved' &&
      !editableFieldsForApproved.includes(inputId)
    ) {
      return true;
    } else if (
      data.status === 'pending' &&
      !editableFieldsForPending.includes(inputId)
    ) {
      return true;
    }
    return false;
  };

  if (isLoading) return <Loading />;

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          <div className="left">
            <img
              src={
                data.img ||
                'https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg'
              }
              alt=""
            />
          </div>
          <div className="right">
            <form onSubmit={handleUpdate}>
              <div className="formInput">
                <label htmlFor="file">
                  Image: <DriveFolderUploadOutlinedIcon className="icon" />
                </label>
                <input
                  type="file"
                  id="file"
                  onChange={(e) => setFile(e.target.files[0])}
                  style={{ display: 'none' }}
                  disabled={shouldDisableInput('file')}
                  autoComplete="off"
                />
              </div>
              {data.status === 'approved' && (
                <div className="formInput">
                  <label htmlFor="additionalPrice">Update Price</label>
                  <input
                    id="additionalPrice"
                    type="text"
                    placeholder="Update Share value"
                    onChange={handleInput}
                    value={additionalData.price || ''}
                    autoComplete="off"
                  />
                </div>
              )}
              {inputs.map((input, index) => (
                <div className="formInput" key={index}>
                  <label htmlFor={input.id}>{input.label}</label>
                  <input
                    type={input.type}
                    placeholder={input.placeholder}
                    id={input.id}
                    onChange={handleInput}
                    value={data[input.id] || ''}
                    disabled={shouldDisableInput(input.id)}
                    autoComplete="off"
                  />
                </div>
              ))}
              <button type="submit" className="submitBtn">
                Update
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateUser;
