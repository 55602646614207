export const userColumns = [
  { field: 'id', headerName: 'ID', width: 80 },
  {
    field: 'user',
    headerName: 'User',
    width: 230,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img className="cellImg" src={params.row.img} alt="avatar" />
          {params.row.displayName}
        </div>
      );
    },
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 250,
  },
  // {
  //   field: "code",
  //   headerName: "Branch Code",
  //   width: 100,
  // },

  {
    field: 'address',
    headerName: 'Address',
    width: 250,
  },
  // {
  //   field: "phone",
  //   headerName: "Phone",
  //   width: 150,
  // },
  // {
  //   field: "membershipCode",
  //   headerName: "membershipCode",
  //   width: 150,
  // },
  // {
  //   field: 'inputter',
  //   headerName: 'inputter',
  //   width: 150,
  // },
  // {
  //   field: "account",
  //   headerName: "Account No.",
  //   width: 150,
  // },
  // {
  //   field: "channel",
  //   headerName: "Channel of Payment",
  //   width: 150,
  // },
  // {
  //   field: "card",
  //   headerName: "Ghana Card",
  //   width: 150,
  // },
  // {
  //   field: "kin",
  //   headerName: "Next of Kin",
  //   width: 100,
  // },
  {
    field: 'status',
    headerName: 'Status',
    width: 100,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status}
        </div>
      );
    },
  },
];
