import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import logo from "../../Assets/logo.png";
import "./printuser.scss";
import BeatLoader from "react-spinners/BeatLoader";

const PrintUser = () => {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const getUser = async () => {
      try {
        const userDoc = await getDoc(doc(db, "users", id));
        if (userDoc.exists()) {
          setUser(userDoc.data());
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false);
      }
    };

    getUser();
  }, [id]);


  const handlePrint = () => {
    window.print();
  };

  if (loading) {
    return (
      <div className="loaderContainer">
        <BeatLoader color="#7451f8" />
      </div>
    );
}

  return (
    <div className="printuser">
      <div className="header">
        <img src={logo} alt="Company Logo" className="company-logo" />
        <h1>Kwahu Praso Rural Bank</h1>
        <h2>Shareholder Receipt</h2>
      </div>
      {user && (
        <div className="userdata">
          <p>
            Receipt to {user.displayName || "N/A"} with an
            amount of GH₵ {user.price || "N/A"} for the
            purchase of {" "} {user.share || "N/A"} shares on{" "}
            {new Date().toLocaleDateString()} from Kwahu Praso Rural Bank.
          </p>

          <div className="details">
            <p>
              <strong>Email:</strong> {user.email || "N/A"}
            </p>
            <p>
              <strong>Phone:</strong> {user.phone || "N/A"}
            </p>
            <p>
              <strong>Address:</strong> {user.address || "N/A"}
            </p>
            <p>
              <strong>National ID:</strong> {user.card || "N/A"}
            </p>
            {/* <p>
              <strong>Bank Branch:</strong> {user.bankBranch || "N/A"}
            </p> */}
            <p>
              <strong>Sign:</strong> ........................
            </p>
          </div>
        </div>
      )}

      <button onClick={handlePrint} className="print-button">
        Print
      </button>
    </div>
  );
};

export default PrintUser;
