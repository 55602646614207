import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useEffect, useState, useCallback } from "react";
import "./single.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import UserHistory from "../../components/UserHistory/UserHistory";
import Chart from "../../components/chart/Chart";
import Datatable from "../../components/datatable/Datatable";
import { Link } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";

const Single = () => {
  const { userId } = useParams();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // Add this for the loading state

  const fetchUser = useCallback(async () => {
    try {
      const docSnap = await getDoc(doc(db, "users", userId));

      if (docSnap.exists()) {
        const userData = docSnap.data();

        if (userData.img) {
          userData.imageUrl = userData.img;
        }

        setUser(userData);
      } else {
        console.log("No such document!"); // This log is okay since it doesn't display user data.
      }
    } catch (err) {
      console.log("Error getting document:", err); // This log can help in debugging, consider removing in production if you want.
    } finally {
      setLoading(false); // Set loading to false when data fetching is done
    }
  }, [userId]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  if (loading) {
    // Display the BeatLoader while loading
    return (
      <div className="loaderContainer">
        <BeatLoader color="#7451f8" />
        <p>Loading...</p>
      </div>
    );
  }
  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <div className="left">
            <Link to={`/users/edit/${userId}`}>
              <div className="editButton">Edit</div>
            </Link>
            <h1 className="title">Information</h1>
            <div className="item">
              <img
                src={user.imageUrl || "fallback_image_url"}
                alt=""
                className="itemImg"
              />

              <div className="details">
                <div className="detailItem">
                  <span className="itemKey">Name:</span>
                  <span className="itemValue">{user.displayName}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Email:</span>
                  <span className="itemValue">{user.email}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">membershipCode:</span>
                  <span className="itemValue">{user.membershipCode}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Branch Code:</span>
                  <span className="itemValue">{user.code}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Phone:</span>
                  <span className="itemValue">{user.phone}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Address:</span>
                  <span className="itemValue">{user.address}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Account:</span>
                  <span className="itemValue">{user.account}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Channel of Payment :</span>
                  <span className="itemValue">{user.channel}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Number of Shares:</span>
                  <span className="itemValue">{user.share}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Share Value:</span>
                  <span className="itemValue">GH₵ {user.price}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Next of Kin:</span>
                  <span className="itemValue">{user.kin}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Ghana Card:</span>
                  <span className="itemValue">{user.card}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="UserHistory">
          <UserHistory userId={`${userId}`} />
        </div>
        <div className="bottom">
          <h1 className="title">Shareholders</h1>
          <Datatable />
        </div>
      </div>
    </div>
  );
};

export default Single;
